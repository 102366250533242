import React, { useState } from "react"

import { Link, useStaticQuery, graphql } from "gatsby"

const Header = () => {
  const [navbarOpen, setNavbarOpen] = useState(false)
  const data = useStaticQuery(graphql`
    query {
      logo: file(absolutePath: { regex: "/unlockphone-logo.svg/" }) {
        publicURL
      }
    }
  `)
  return (
    <header className="global-header">
      <nav className="navbar navbar-expand-lg" width="145" height="28">
        <div className="container-lg">
          <Link className="navbar-brand" to="/">
            <img src={data.logo.publicURL} alt="unlockphone" />
          </Link>
          <button
            onClick={() => setNavbarOpen(!navbarOpen)}
            className={
              navbarOpen ? "navbar-toggler" : "navbar-toggler collapsed"
            }
            type="button"
            aria-controls="navbarHeader"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 340 340"
                enableBackground="new 0 0 340 340"
              >
                <rect y="277" width="340" height="42" />

                <rect y="149" width="340" height="42" />

                <rect y="21" width="340" height="42" />
              </svg>
            </span>
          </button>
          <div
            className={
              navbarOpen
                ? "collapse navbar-collapse show"
                : "collapse navbar-collapse"
            }
            id="navbarHeader"
          >
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/how-to"
                  activeClassName="active"
                >
                  How To Guides
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/why-unlock-your-phone/"
                  activeClassName="active"
                >
                  Why unlock your phone
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/how-to-guides/"
                  activeClassName="active"
                >
                  How to guides
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/trusted-services/"
                  activeClassName="active"
                >
                  Trusted services
                </Link>
              </li> */}
            </ul>
            {/* <div className="search-header">
              <form className="form-inline">
                <input
                  className="form-control input-search"
                  type="search"
                  aria-label="Search"
                />
                <button
                  className="btn btn-search"
                  type="submit"
                  name="Submit"
                  aria-label="Submit"
                >
                  <svg
                    enableBackground="new 0 0 515.558 515.558"
                    height="512"
                    viewBox="0 0 515.558 515.558"
                    width="512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m378.344 332.78c25.37-34.645 40.545-77.2 40.545-123.333 0-115.484-93.961-209.445-209.445-209.445s-209.444 93.961-209.444 209.445 93.961 209.445 209.445 209.445c46.133 0 88.692-15.177 123.337-40.547l137.212 137.212 45.564-45.564c0-.001-137.214-137.213-137.214-137.213zm-168.899 21.667c-79.958 0-145-65.042-145-145s65.042-145 145-145 145 65.042 145 145-65.043 145-145 145z" />
                  </svg>
                </button>
              </form>
            </div> */}
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header
