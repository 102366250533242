import React from "react"
import Header from "./Header"
import Footer from "./Footer"

const Layout = ({ location, title, children }) => {
  return (
    <div className="global-wrapper">
      <Header siteTitle={title} location={location} />
      <main className="global-main mt-5 mb-5">{children}</main>
      <Footer siteTitle={title} />
    </div>
  )
}

export default Layout
