import React from "react"

const Footer = () => {
  return (
    <footer className="global-footer bg-dark">
      <div className="container">
        <div className="row">
          <div className="col">© unlockphone {new Date().getFullYear()}</div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
